import React, { Component } from "react"
import { navigate } from "gatsby"
import base64 from "base-64"
import ReactLoading from "react-loading"
import Helmet from "react-helmet"
import "../styles/global.scss"
import "../styles/login.scss"
import LoginBackground from "../components/login/login-background.js"
import axios from "axios"
import envUtil from "../utilities/env-util.js"
import telstraLogo from "../images/telstra-logo-magenta.png"
import ToastContent from "../components/common/toast-content.js"
import "react-toastify/dist/ReactToastify.css"
import { ToastContainer, toast } from "react-toastify"
import { login } from "../utilities/userManager"

axios.defaults.withCredentials = true

class IndexPage extends Component {
  constructor(props) {
    super(props)
    this.state = this.initializeState()
  }

  initializeState = () => {
    let state = {
      loginData: {
        username: "",
        password: "",
      },
      loadingPending: false,
    }
    return state
  }

  inputTextHandler = (evt) => {
    var newState = Object.assign({}, this.state)
    newState.loginData[evt.target.name] = evt.target.value
    this.setState(newState)
  }

  handleKeyPress = (evt) => {
    if (evt.key === "Enter") {
      this.loginHandler()
    }
  }

  loginHandlerWithOidc = () => {
    login()
  }

  loginHandler = () => {
    var newState = Object.assign({}, this.state)
    newState.loadingPending = true
    this.setState(newState, () => {
      setTimeout(() => {
        let basicAuthHeader =
          "Basic " +
          base64.encode(
            this.state.loginData.username + ":" + this.state.loginData.password
          )

        // Create an unintercepted axios instance. Otherwise, the basic token will be overwritten
        const uninterceptedAxiosInstance = axios.create()
        uninterceptedAxiosInstance({
          method: "POST",
          baseURL: envUtil.getBaseURL(envUtil.tenants.midMarket),
          url: "/api/v1/tokens/",
          headers: {
            "Authentication-Data": basicAuthHeader,
          },
          withCredentials: true,
        })
          .then((response) => {
            this.loginSuccessAPIHandler(response.data)
            return null
          })
          .catch((err) => {
            if (err.response && err.response.data && err.response.data.error) {
              this.loginFailAPIHandler(err.response.data.error)
            } else if (
              err.response &&
              err.response.data &&
              err.response.data.message
            ) {
              this.loginFailAPIHandler(err.response.data.message)
            } else if (err.message) {
              this.loginFailAPIHandler(err.message)
            } else {
              this.loginFailAPIHandler(
                "Invalid username or password. Please try again."
              )
            }
            return null
          })
      }, 150)
    })
  }

  loginSuccessAPIHandler = (responseBody) => {
    localStorage.setItem("cmiPortalToken", responseBody.token)
    localStorage.setItem("cmiPortalUsername", responseBody.username)
    localStorage.setItem(
      "cmiPortalMemberOf",
      JSON.stringify(responseBody.memberOf)
    )
    let landingPage = responseBody.memberOf[0].toLowerCase().replace("_", "-")
    navigate("/" + landingPage + "/")
  }

  loginFailAPIHandler = (error) => {
    var newState = Object.assign({}, this.state)
    newState.loadingPending = false
    newState.loginData.password = ""
    this.setState(newState, () => {
      toast.error(<ToastContent>{error}</ToastContent>)
    })
  }

  render() {
    return (
      <div className="login-root">
        <LoginBackground classToApply="background-image login-background" />
        <Helmet
          title="Login - MNaaS Portal"
          meta={[
            { name: "description", content: "Login page for the MNaaS Portal" },
          ]}
        >
          <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
          />
          <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/icon?family=Material+Icons"
          />
          <html lang="en" />
        </Helmet>
        <ToastContainer
          position="top-center"
          autoClose={3000}
          pauseOnHover={false}
          pauseOnFocusLoss={false}
          className="toast-container"
          toastClassName="toast-info"
          bodyClassName="toast-body"
        />
        <div className="login-box-container">
          <div className="login-box-content">
            <h1>MNaaS Portal</h1>
            <img alt="Telstra Logo" src={telstraLogo} />
            {this.state.loadingPending ? (
              <ReactLoading
                style={{
                  marginTop: "0",
                  marginLeft: "auto",
                  marginRight: "auto",
                  width: "50px",
                  height: "50px",
                  position: "absolute",
                }}
                type={"spokes"}
                color={"#444444"}
              />
            ) : null}
            {envUtil.getPlatform() === "openshift" ? (
              <>
                <input
                  type="text"
                  disabled={this.state.loadingPending}
                  autoComplete="off"
                  autoCapitalize="off"
                  spellCheck="false"
                  name="username"
                  value={this.state.loginData.username}
                  placeholder="Username"
                  onChange={(evt) => this.inputTextHandler(evt)}
                  onKeyPress={(evt) => this.handleKeyPress(evt)}
                />
                <input
                  type="password"
                  name="password"
                  disabled={this.state.loadingPending}
                  value={this.state.loginData.password}
                  placeholder="Password"
                  onChange={(evt) => this.inputTextHandler(evt)}
                  onKeyPress={(evt) => this.handleKeyPress(evt)}
                />
                <button
                  disabled={this.state.loadingPending}
                  className="button-login margin-top-10px"
                  onClick={this.loginHandler}
                >
                  Log In
                </button>
              </>
            ) : (
              <button
                disabled={this.state.loadingPending}
                className="button-login margin-top-10px"
                onClick={this.loginHandlerWithOidc}
              >
                Login with OIDC
              </button>
            )}

            <div className="info-caption">
              <p>Need an account? Sign Up through AGS</p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default IndexPage
