import React from "react"
import infoIcon from "../../images/info-icon-white.png"

const ToastContent = ({ children }) => (
  <div className="toast-content">
    <img src={infoIcon} alt="Toast Icon" />
    <span>{children}</span>
  </div>
)

export default ToastContent
